import { Emitter } from '../events'
import { qsa, lerp } from '../utils'
import gsap from 'gsap'

export default class Cursor {
  constructor(obj) {
    this.el = obj.el
    this.isHovering = false
    this.positionX = 0
    this.positionY = 0
    this.icons = []
    this.icon = null
    this.triggers = qsa('[data-hover]')
    this.tl = gsap.timeline()
    this.options = {
      ease: 0.12,
      alpha: 0,
    }

    this.init()
  }

  setup() {
    const el = document.createElement('div')
    el.classList.add('cursor-inner')
    //el.innerHTML = 'My Cursor'
    const defaultIcon = { el: el, target: 'default' }
    this.icons.push(defaultIcon)
  }

  onEnter = (el) => {
    this.isHovering = true
    const target = el.target
    const dataset = target.dataset

    this.icons.forEach((icon) => {
      if (icon.target === dataset.hover) {
        this.icon = icon.el.outerHTML
        this.el.innerHTML = this.icon
      }
    })

    this.animateIn()
  }

  onLeave = () => {
    this.isHovering = false

    if (!this.icon) return
    this.animateOut()
  }

  animateIn() {
    this.tl.clear()
    this.tl.to(this.el, { autoAlpha: 1, duration: 0.8 })
  }

  animateOut() {
    this.tl.clear()
    this.tl.to(this.el, {
      autoAlpha: 0,
      duration: 0.8,
      onComplete: () => {
        if (this.icon) {
          this.el.innerHTML = ''
          this.icon = null
        }
      },
    })
  }

  tick(obj) {
    const mouse = obj.mouse
    this.positionX = lerp(this.positionX, mouse.x, this.options.ease)
    this.positionY = lerp(this.positionY, mouse.y, this.options.ease)

    gsap.set(this.el, {
      x: this.positionX,
      y: this.positionY,
    })
  }

  on() {
    Emitter.on('tick', this.tick.bind(this))
    this.triggers.forEach((trigger, index) => {
      trigger.addEventListener('mouseenter', this.onEnter)
      trigger.addEventListener('mouseleave', this.onLeave)
    })
  }

  off() {
    Emitter.off('tick', this.tick)
    this.triggers.forEach((trigger) => {
      trigger.removeEventListener('mouseenter', this.onEnter)
      trigger.removeEventListener('mouseleave', this.onLeave)
    })
    this.triggers = null
    this.icon = null
  }

  init() {
    this.setup()
    this.on()
  }
}
