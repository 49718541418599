import Emitter from 'tiny-emitter'
import SplitText from '../components/SplitText'

export const splitText = (data) => {
  const emitter = new Emitter()
  const splits = []
  let splitted = 0
  const totalSplits = data.length

  data.forEach((el) => {
    const dataset = el.dataset.split
    const data = dataset.split(',')
    const split = {}
    const classes = {}

    split.el = el
    splitted++

    data.forEach((type, i) => {
      const filter = type.trim()
      if (filter == 'lines') classes.linesClass = `line-${i}`
      if (filter == 'words') classes.wordsClass = `word-${i}`
      if (filter == 'chars') classes.charsClass = `char-${i} chr-++`
    })

    const splitText = new SplitText(el, {
      type: dataset.toString(),
      ...classes,
    })

    splits.push(splitText)

    if (splitted == totalSplits) {
      setTimeout(() => {
        emitter.emit('ready', splits)
      }, 0) // Use a small delay to ensure the event listener is set up
    }
  })

  return emitter
}

export const reverseSplit = (data) => {
  data.forEach((split) => {
    split.revert()
  })
}
