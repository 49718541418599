import store from '../../store'
import Default from './default'
import { qs, qsa, bindAll } from '../../utils'
import { SingleIn } from '../../components'

class Single extends Default {
  constructor(opt = {}) {
    super(opt)
    bindAll(this, 'animateIn', 'preload')
    this.slug = 'single'
  }

  onEnter() {
    super.onEnter()
  }

  onEnterCompleted() {
    super.onEnterCompleted()
    this.addElements()
    this.addEvents()
    this.animateIn()
  }

  addElements() {
    this.heroAnimation = SingleIn()
  }

  addEvents() {}

  preload() {
    super.preload()
    this.animateIn()
  }

  animateIn() {
    if (!store.flags.preloaded) return

    console.log('animate')

    this.heroAnimation && this.heroAnimation.play()
  }

  removeEvents() {}

  onLeave() {
    super.onLeave()
    this.removeEvents()
  }

  onLeaveCompleted() {}
}

export default Single
