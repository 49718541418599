import { qs, qsa } from '../utils'
import gsap from 'gsap'
import store from '../store'

export default class Form {
  constructor() {
    this.dom = {
      form: qs('form'),
      inputs: qsa('input'),
      text: qs('textarea'),
      select: qs('select'),
      message: qs('.contact-message'),
    }

    this.init()
  }

  handleSubmit = (e, form) => {
    e.preventDefault()

    const formData = new FormData(form)

    console.log(new URLSearchParams(formData).toString())

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        this.resetForm(form)
      })
      .catch((error) => console.log(error))
  }

  resetForm(form) {
    const formSuccess = qs('.contact-success', form)

    // gsap.fromTo(
    //   formSuccess,
    //   { y: 20, autoAlpha: 0 },
    //   { duration: 0.4, y: 0, autoAlpha: 1 },
    // )

    // setTimeout(() => {
    //   gsap.fromTo(
    //     formSuccess,
    //     { y: 0, autoAlpha: 1 },
    //     { duration: 0.4, y: 20, autoAlpha: 0 },
    //   )
    // }, 3000)

    form.reset()
  }

  on() {
    const { form } = this.dom
    form.addEventListener('submit', (e) => this.handleSubmit(e, form))
  }

  off() {
    const { form } = this.dom
    form.removeEventListener('submit', (e) => this.handleSubmit(e, form))
  }

  init() {
    if (!this.dom.form) return
    this.on()
  }
}
