import { qs, qsa } from '../utils'
import gsap from 'gsap'
import store from '../store'
import SmoothScroll from './SmoothScroll'

export default class Testimonial {
  constructor() {
    this.dom = {
      testimonials: qs('.testimonials'),
      items: qsa('.testimonials-item '),
      nav: qsa('.item-nr'),
      timer: qs('.timer-inner'),
    }

    this.state = {
      current: 0,
      total: this.dom.items.length,
      animate: false,
    }

    this.init()
  }

  nextTestimonial = (e) => {
    const { items, nav } = this.dom
    const current = e.target
    const id = current.dataset.id
    const testimonial = items[id]

    this.tl.kill()
    this.tl.set(this.dom.timer, { scaleY: 1 })
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }

    this.animateTestimonial(current, testimonial)
  }

  animateTestimonial(current, testimonial) {
    const { items, nav } = this.dom

    if (this.state.animate) return

    this.state.animate = true

    items.forEach((item) => {
      item.classList.remove('active')
      gsap.set(item, { autoAlpha: 0, position: 'absolute' })
    })
    nav.forEach((item) => {
      item.classList.remove('active')
    })

    current.classList.add('active')

    const header = qs('.item-header', testimonial)
    const footer = qs('.item-footer', testimonial)

    gsap.set(testimonial, { autoAlpha: 1, position: 'relative' })
    gsap.from(header, {
      duration: 0.8,
      autoAlpha: 0,
      y: 40,
      ease: 'power2.out',
    })
    gsap.from(footer, {
      duration: 0.8,
      autoAlpha: 0,
      delay: 0.25,
      y: 20,
      ease: 'power2.out',
      onComplete: () => {
        this.state.animate = false
      },
    })

    SmoothScroll && SmoothScroll.update()
  }

  on() {
    const { nav, items } = this.dom

    nav.forEach((item) => {
      item.addEventListener('click', this.nextTestimonial)
    })
  }

  init() {
    if (!this.dom.testimonials) return
    this.on()

    this.timer = setInterval(() => {
      this.state.current =
        this.state.current >= this.state.total - 1 ? 0 : this.state.current + 1
      const current = this.dom.nav[this.state.current]
      const id = current.dataset.id
      const testimonial = this.dom.items[id]
      this.animateTestimonial(current, testimonial)
    }, 4000)

    this.tl = gsap.timeline()
    this.tl.to(this.dom.timer, {
      duration: 4,
      scaleY: 1,
      ease: 'power3.inOut',
      repeat: -1,
    })
  }
}
